import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Weeks = () => {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    const getWeeks = async () => {
      const resp = await fetch(
        "https://report-hub.simplydelish.workers.dev/reports/weekly/all"
      );
      const weeksResp = await resp.json();
      let weeksArray = [];
      let weeksObject = weeksResp["data"];

      for (const i in weeksObject) {
        let objectW = weeksObject[i];
        let objectPrior = weeksObject[i - 1];
        let objectPrior2 = weeksObject[i - 2];
        weeksArray.push({
          id: objectW,
          prior: objectPrior,
          prior2: objectPrior2,
        });
      }

      // setWeeks(JSON.stringify(weeksArray));
      setWeeks(weeksArray);
    };

    getWeeks();
  }, []);

  return (
    <div className="week">
      <h1>Weeks Ending</h1>
      <h2>
        <ol>
          {weeks.map((week) => (
            <li key={week.id}>
              <Link
                to={`/weeks/${week.id}?prior=${week.prior}&prior2=${week.prior2}`}
              >
                {week.id}
              </Link>
            </li>
          ))}
        </ol>
      </h2>
    </div>
  );
};

export default Weeks;
