import { Router } from "@reach/router";
import "./App.css";

import Weeks from "./components/weeks";
import Week from "./components/week";

// import Months from './components/months';
// import Month from './components/month';

function App() {
  return (
    <Router>
      <Weeks path="/" />
      <Week path="/weeks/:id" />
      <Week path="/report" />
    </Router>
  );
}

export default App;
