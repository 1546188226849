import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Week = ({ id, prior, prior2 }) => {
  const [week, setWeek] = useState({});

  useEffect(() => {
    const getWeek = async () => {
      let resp,
        priordata,
        priordata2 = await Promise.all([
          fetch(
            `https://report-hub.simplydelish.workers.dev/reports/weekly/end/${id}`
          ),
          fetch(
            `https://report-hub.simplydelish.workers.dev/reports/weekly/end/${prior}`
          ),
          fetch(
            `https://report-hub.simplydelish.workers.dev/reports/weekly/end/${prior2}`
          ),
        ]);
      const weekResp = await resp.json();
      let data = weekResp["data"];
      let nameData = 0;
      let dataArray = [];

      for (const key in data) {
        if (
          Math.round(data[key]) !== data[key] &&
          toString(data[key]).includes("/")
        ) {
          nameData = parseFloat(data[key]).toFixed(2);
        } else {
          nameData = data[key];
        }

        dataArray.push({ name: key, data: nameData });
      }

      setWeek(dataArray);
    };

    getWeek();
  }, [id]);

  if (!Object.keys(week).length) return <div />;

  return (
    <table className="week">
      <h1>Week Ending - {week[1].data}</h1>
      <tbody className="week-table">
        {week.map((week) => (
          <tr key={week.name}>
            <td>{week.name}</td>
            <td>{week.data}</td>
          </tr>
        ))}
      </tbody>
      <p>
        <Link to="/">Go back</Link>
      </p>
    </table>
  );
};

export default Week;
